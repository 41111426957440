.addlist .el-icon-plus:before {
  content: "\e6d9";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-switch .el-switch__core {
  width: 40px !important;
}
.table-switch .el-switch__core:after {
  background-color: #fff;
}
.table-switch span {
  line-height: 20px;
  margin-left: 5px;
}
.operationControl > div > div > span {
  min-width: 6rem;
}
.itemLabel {
  display: inline-block;
  width: 3rem;
}
.sharingCode {
  display: block;
  margin: 0 auto;
  width: 350px;
  height: 350px;
}
